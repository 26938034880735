var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "form form--narrow" }, [
    _c("h2", {
      staticClass: "form__heading",
      domProps: {
        textContent: _vm._s(
          _vm.$route.name == "setPassword"
            ? "Change Password"
            : "Reset Password"
        ),
      },
    }),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { action: "#", method: "post" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.resetPassword()
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "form__group" },
          [
            _c("Password", {
              ref: "password",
              attrs: {
                name: "password",
                label: "Password",
                validation: _vm.passwordValidation,
                toggled: _vm.passwordToggled,
              },
              on: {
                "update:toggled": function ($event) {
                  _vm.passwordToggled = $event
                },
                change: function ($event) {
                  return _vm.passwordChange()
                },
              },
              model: {
                value: _vm.data.password,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.data,
                    "password",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "data.password",
              },
            }),
            _vm._v(" "),
            _c("Password", {
              ref: "confirmPassword",
              attrs: {
                name: "confirmPassword",
                label: "Confirm password",
                validation: [
                  _vm.passwordValidation,
                  _vm.confirmPasswordMatchesValidation,
                ],
                toggled: _vm.confirmPasswordToggled,
              },
              on: {
                "update:toggled": function ($event) {
                  _vm.confirmPasswordToggled = $event
                },
              },
              model: {
                value: _vm.data.confirmPassword,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.data,
                    "confirmPassword",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "data.confirmPassword",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.error
          ? _c("div", { staticClass: "form__group form__group--message" }, [
              _c("p", { staticClass: "form__message form__message--error" }, [
                _c("span", {
                  staticClass: "form__message-icon",
                  domProps: { innerHTML: _vm._s(_vm.svg.error) },
                }),
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$route.name == "setPassword"
                        ? "Change password failed"
                        : "Reset password failed"
                    ) +
                    "\n      "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form__button-group form__button-group--right" },
          [
            _c("fs-button-main", { attrs: { loading: _vm.loading } }, [
              _vm._v(
                _vm._s(_vm.$route.name == "setPassword" ? "Change" : "Reset")
              ),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }