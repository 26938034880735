<template>
  <section class="form form--narrow">
    <h2 class="form__heading" v-text="$route.name == 'setPassword' ? 'Change Password' : 'Reset Password'"></h2>
    <form action="#" method="post" @submit.prevent="resetPassword()">
      <div class="form__group">
        <Password
          name="password"
          ref="password"
          label="Password"
          v-model.trim="data.password"
          :validation="passwordValidation"
          :toggled.sync="passwordToggled"
          @change="passwordChange()"
        />
        <Password
          name="confirmPassword"
          ref="confirmPassword"
          label="Confirm password"
          v-model.trim="data.confirmPassword"
          :validation="[passwordValidation, confirmPasswordMatchesValidation]"
          :toggled.sync="confirmPasswordToggled"
        />
      </div>
      <div class="form__group form__group--message" v-if="error">
        <p class="form__message form__message--error">
          <span class="form__message-icon" v-html="svg.error"></span>
          {{ $route.name == 'setPassword' ? 'Change password failed' : 'Reset password failed' }}
        </p>
      </div>
      <div class="form__button-group form__button-group--right">
        <fs-button-main :loading="loading">{{ $route.name == 'setPassword' ? 'Change' : 'Reset' }}</fs-button-main>
      </div>
    </form>
  </section>
</template>

<script>
import FsButtonMain from '@components/buttons/fs-button-main.vue';
import Password from './../components/password-field.vue';

import error from '../../img/baseline-error-24px.svg';

export default {
  load({ store, route }) {
    store.head.title = `FindSourcing – ${route.name == 'setPassword' ? 'Change Password' : 'Reset Password'}`;
  },
  components: { FsButtonMain, Password },
  data() {
    return {
      loading: false,
      svg: {
        error,
      },
      data: {
        id: this.$route.query.userId,
        code: this.$route.query.code,
        password: '',
        confirmPassword: '',
      },
      passwordToggled: false,
      confirmPasswordToggled: false,
      error: false,
    };
  },
  methods: {
    passwordValidation(val) {
      if (val === '') return 'Password is required';
      if (val.length < 6) return 'Password need to be at least 6 characters';
    },
    confirmPasswordMatchesValidation(val) {
      if (val !== this.data.password) return "Passwords don't match";
    },
    passwordChange() {
      if (this.$refs.confirmPassword.validated) this.$refs.confirmPassword.change();
    },
    resetPassword() {
      this.loading = true;
      this.error = false;

      Promise.all([this.$refs.password.validates(), this.$refs.confirmPassword.validates()])
        .then((validations) => {
          if (validations.filter((validation) => !validation).length) {
            this.loading = false;
            return;
          }

          return this.$store.user
            .resetPassword(this.data)
            .then(({ status }) => {
              if (status !== 3) {
                this.loading = false;
                this.error = true;
                return;
              }

              this.$notify({
                type: 'success',
                title: 'Password successfully updated',
              });

              this.$router.replace({
                name: 'home',
              });
            })
            .catch((error) => {
              throw new Error(error);
            });
        })
        .catch((error) => {
          this.unknownError = true;
          this.loading = false;
        });
    },
  },
  mounted() {
    // Temp hack for main padding
    try {
      document.getElementsByClassName('main')[0].classList.add('main--padding');
    } catch (e) {}
  },
  beforeDestroy() {
    // Temp hack for main padding
    try {
      document.getElementsByClassName('main')[0].classList.remove('main--padding');
    } catch (e) {}
  },
};
</script>
